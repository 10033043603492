/* global ApplePaySession */
import React, { useEffect, useRef, useState } from "react";
import mainStore from "../store/MainStore";

const ApplePayButton = ({
  entrypoint,
  subdomain,
  executeBefore,
  callBackFunction,
  callBackFunctionError,
  invoiceData,
}) => {
  let token = process.env.REACT_APP_TOKEN;

  const applePaySettings = mainStore.paymentPage.paymentMethods.settings?.applePay || {};
  const buttonStyle = applePaySettings.buttonStyle ?? "black";
  const buttonType = applePaySettings.buttonType ?? "pay";
  const language = applePaySettings.language ?? "en-US";

  const updateApplePayRequestPayload = (applePayRequestPayload) => {
    let totalAmount = mainStore.totalAmount;

    applePayRequestPayload.total.amount = parseFloat(
      totalAmount.totalAmount
    ).toFixed(2);
    applePayRequestPayload.supportedNetworks = mainStore.allowedCards;
    applePayRequestPayload.lineItems = [
      {
        label: "Subtotal",
        amount: parseFloat(totalAmount.netAmount).toFixed(2),
      },
      {
        label: "Service Fee",
        amount: parseFloat(totalAmount.fee).toFixed(2),
      },
    ];
    return applePayRequestPayload;
  };

  const updatePaymentRequest = (paymentRequest) => {
    let totalAmount = mainStore.totalAmount;
    paymentRequest.paymentDetails.totalAmount = parseFloat(
      totalAmount.totalAmount
    ).toFixed(2);
    paymentRequest.paymentDetails.serviceFee = parseFloat(
      totalAmount.fee
    ).toFixed(2);
    paymentRequest.customerData = mainStore.getRequiredPayorFieldsToPay();
    if (invoiceData) {
      paymentRequest.source = "payment-link";
      paymentRequest.invoiceData = invoiceData;
    }
    return paymentRequest;
  };

  const [applePayRequestPayload, setApplePayRequestPayload] = useState({
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: [],
    requiredBillingContactFields: ["postalAddress", "name"],
    lineItems: [],
    total: {
      label: "Confirm Payment",
      amount: "0.00",
    },
  })

  const [paymentRequest, setPaymentRequest] = useState({
    entryPoint: entrypoint,
    subdomain: subdomain,
    paymentMethod: {
      method: "wallet",
      walletType: "apple_pay",
      walletToken:
        "<base 64 string of event.payment received in onpaymentauthorized>",
    },
    paymentDetails: {
      totalAmount: 0.0,
      serviceFee: 0.0,
    },
    customerData: "<customerData>",
    source: "payment-page",
  })

  const buttonRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (mainStore.canUseApplePay() && entrypoint && subdomain) {
      const button = buttonRef.current;
      if (button) {
        button.addEventListener("click", async () => {
          if (!window.ApplePaySession) {
            return;
          }

          const newApplePayRequestPayload =updateApplePayRequestPayload(
            applePayRequestPayload
          );
          setApplePayRequestPayload(newApplePayRequestPayload);
          const session = new ApplePaySession(3, applePayRequestPayload);

          const value = await executeBefore();
          if(!value) {
            return session.abort()
          }

          try {
            // Create ApplePaySession
            session.onvalidatemerchant = async (event) => {
              // Call your own server to request a new merchant session.
              const response = await fetch(
                `${process.env.REACT_APP_URL_API}Wallet/applepay/session`,
                {
                  method: "POST",
                  body: JSON.stringify({
                    entry: entrypoint,
                    domainName: window.location.hostname,
                    displayName: "",
                    validationURL: "",
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    requestToken: token,
                  },
                }
              );

              const data = await response.json();
              session.completeMerchantValidation(data);
            };

            // session.onpaymentmethodselected = event => {
            //     // Define ApplePayPaymentMethodUpdate based on the selected payment method.
            //     // No updates or errors are needed, pass an empty object.
            //     const update = {};
            //     session.completePaymentMethodSelection(update);
            // };

            // session.onshippingmethodselected = event => {
            //     // Define ApplePayShippingMethodUpdate based on the selected shipping method.
            //     // No updates or errors are needed, pass an empty object.
            //     const update = {};
            //     session.completeShippingMethodSelection(update);
            // };

            // session.onshippingcontactselected = event => {
            //     // Define ApplePayShippingContactUpdate based on the selected shipping contact.
            //     const update = {};
            //     session.completeShippingContactSelection(update);
            // };

            session.onpaymentauthorized = async (event) => {
              // Define ApplePayPaymentAuthorizationResult
              let walletToken = base64Encode(JSON.stringify(event.payment));
              const newPaymentRequest = {...paymentRequest}
              newPaymentRequest.paymentMethod.walletToken = walletToken;
              newPaymentRequest.paymentMethod.cardHolder = `${event.payment.billingContact.givenName} ${event.payment.billingContact.familyName}`;
              setPaymentRequest(updatePaymentRequest(newPaymentRequest));
              const payload = {
                ...paymentRequest,
                customerData: {
                  ...paymentRequest.customerData,
                  billingAddress1:
                    event.payment.billingContact.addressLines[0] || "",
                  billingAddress2:
                    event.payment.billingContact.addressLines[1] || "",
                  billingCity: event.payment.billingContact.locality || "",
                  billingState:
                    event.payment.billingContact.administrativeArea || "",
                  billingZip: event.payment.billingContact.postalCode || "",
                  billingCountry: event.payment.billingContact.country || "",
                },
              };
              const response = await fetch(
                `${process.env.REACT_APP_URL_API}MoneyIn/getpaid?async=false`,
                {
                  method: "POST",
                  body: JSON.stringify(payload),
                  headers: {
                    "Content-Type": "application/json",
                    requestToken: mainStore.getPageToken(),
                  },
                }
              );

              const data = await response.json();
              let result;
              if (data.responseText === "Success") {
                result = {
                  status: ApplePaySession.STATUS_SUCCESS,
                };
              } else {
                result = {
                  status: ApplePaySession.STATUS_FAILURE,
                };
              }

              if (callBackFunction) {
                callBackFunction(data);
              }
              session.completePayment(result);
            };

            session.oncancel = (event) => {
              // Payment canceled by WebKit
            };

            session.begin();
          } catch (e) {
            if (callBackFunctionError) {
              callBackFunctionError(`Apple Pay, ${e.message}`);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const base64Encode = (str) => {
    const buffer = new TextEncoder().encode(str);
    const binaryStr = String.fromCharCode.apply(null, buffer);
    return btoa(binaryStr);
  };

  return (
    <div className="text-center">
      <apple-pay-button
        ref={buttonRef}
        buttonstyle={buttonStyle}
        type={buttonType}
        locale={language}
      ></apple-pay-button>
    </div>
  );
};

export default ApplePayButton;
